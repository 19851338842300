import { BaseEntityModel } from '@common-src/model/base-model';
// import { FormControl, FormControlType, FormControlModel, FormControlOptionModel, FormControlTextModel } from '@common-src/model/form-control';
import { QueryPageModel } from '@common-src/model/query-model';
// import CommonService from '@common-src/service/common';
import { ServiceType } from '@common-src/model/enum';

export class ServiceCenterEntityModel extends BaseEntityModel {
    constructor(type: ServiceType = ServiceType.SERVICE_REQUEST) {
        super();
        this.type = type;
    }

    type: ServiceType;
    id: string = undefined;
    title: string = undefined;
    code: string = undefined;
    levelDesc: string = undefined;
    deviceId: string = undefined;
    deviceName: string = undefined;
    locationId: string = undefined;
    locationName: string = undefined;
    flowStatus: string = undefined;
    flowStatusName: string = undefined;
    createdDT: string = undefined;
    createdBy: string = undefined;
    applicant: string = undefined;
    processInstanceId: string = undefined;
    serviceCategory: string = undefined;
    assignee: string = undefined;

    static getTableColumns() {
        return [
            {
                title: '编号',
                dataIndex: 'code',
                width: 300
            },
            {
                title: '报修对象',
                dataIndex: 'deviceName'
            },
            {
                title: '报修位置',
                dataIndex: 'locationName'
            },
            {
                title: '申请人',
                dataIndex: 'applicant'
            },
            {
                title: '申请时间',
                dataIndex: 'createdDT',
                scopedSlots: { customRender: 'createdDT' }
            },
            {
                title: '等级',
                dataIndex: 'levelDesc'
            },
            {
                title: '状态',
                dataIndex: 'flowStatusName'
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(json) {
        super.toModel(json);
        this.applicant = _.get(_.get(json, 'formProperties'), 'applicant');
        return this;
    }

    toService() {
        const data = super.toService();
        if (data.level && data.level === '') {
            delete data.level;
        }
        delete data.spaceName;
        delete data.typeName;
        return data;
    }
}

export class ServiceCenterQueryModel extends QueryPageModel {
    serviceDeskStatus: string = '';

    toService() {
        const data = super.toService();
        return data;
    }
}
